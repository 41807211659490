import { Flex, Result, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as dd from 'dingtalk-jsapi'

// 获取微应用免登授权码
// https://open.dingtalk.com/document/orgapp/jsapi-request-auth-code
function JsapiAuth() {

  const navigate = useNavigate()

  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (dd.env.platform === 'notInDingTalk') {
      setErrorMsg('notInDingTalk')
    } else {
      dd.requestAuthCode({
        clientId: 'dingwgdx6emlxr3fcrg8', // sales.mycht.cn
        // clientId: 'dingl3jyntkazyg4coxf', // hub.mycht.cn
        corpId: 'ding48bce8fd3957c96b',
        success: (res) => {
          const { code } = res
          setErrorMsg('/p/dingding/callback?state=jsapi-auth&authCode=' + code)


          fetch('https://p9axztuwd7x8a7.mycht.cn/dingtalk/dingtalkwork/Getusers_auth?code=' + code)
            .then((response) => response.json())
            .then((json) => {
              setErrorMsg(JSON.stringify(json))
            })
            .catch((error) => {
              console.log('fetch data failed', error)
            })
          // navigate('/p/dingding/callback?state=global-saels&authCode=' + code, {
          //   replace: true,
          // })
        },
        fail: (error) => {
          setErrorMsg(JSON.stringify(error))
        },
        complete: () => {
          setErrorMsg('complete: ')
        }
      })

      // dd.getAuthCode({
      //   corpId: 'ding48bce8fd3957c96b',
      //   success: (res) => {
      //     const { code } = res
      //     fetch('https://p9axztuwd7x8a7.mycht.cn/dingtalk/dingtalkwork/Getusers_auth?code=' + code)
      //       .then((response) => response.json())
      //       .then((json) => {
      //         setErrorMsg(JSON.stringify(json))
      //       })
      //       .catch((error) => {
      //         console.log('fetch data failed', error)
      //       })
      //   },
      //   fail: (error) => {
      //     setErrorMsg(JSON.stringify(error))
      //   },
      //   complete: () => {
      //     setErrorMsg('complete: ')
      //   }
      // })
    }

  }, [])

  return (
    <Flex justify='center' align='center' gap='middle' vertical>
      <Result
        status='success'
        title='欢迎使用'
        subTitle={'正在获取钉钉登授权' + errorMsg}
        extra={[<Spin key='dingding-login' size='small' />]}
      />
    </Flex>
  )
}

export default JsapiAuth
